import * as React from 'react';
import { Link } from 'react-router-dom';
/* @jsx jsx */ import { css, jsx } from '@emotion/core'; /* eslint-disable-line */

const sideCss = css`
	display: flex;
    align-items: center;
	justify-content: space-between;
	 
	ul {
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			position: relative;
			margin-left: 28px;
			line-height: 1;
			color: #fff;
			text-transform: uppercase;
			text-decoration: none;
			font-size: 16px;
			font-weight: 600;

			> a, > span > a {
				color: #fff;
				font-size: 16px;
				font-weight: 600;
				position: relative;
				text-transform: uppercase;
				text-decoration: none;

				::before {
					content: '';
					height: 2px;
					position: absolute;
					top: 100%;
					left: 50%;
					right: 50%;
					background: #fff;
					opacity: 0;
					transition: .3s cubic-bezier(.45,1.06,.58,1.31);
				}


				&:hover {
					&::before {
					  left: 0;
					  right: 0;
					  opacity: 1;
					}
				  }
			}
		}
	}
	
	.trigger {
		cursor: pointer;
	}
`;

const popupCss = css`
	position: absolute;
	margin-top: 30px;
	left: auto;
	right: 0;
	z-index: 1000;

	a {
		color: #000;
		font-size: 12px;
	}

	> div {
		
		width: 200px;
		position: relative;
		box-shadow: rgba(0,0,0,0.05) 0px 2px 6px, rgba(0,0,0,0.07) 0px 0px 0px 1px;
		animation-name: eMLfYp;
		animation-duration: 0.15s;
		animation-timing-function: ease-in;
		background: rgb(255,255,255);
		padding: 14px;
		border-radius: 2px;

		.title {
			font-family: Montserrat,sans-serif;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.57;
			color: rgb(52,49,50);
		}
	}

`;


const UserMenu = ({ user }) => {
    return <div css={popupCss}>
        <div>
            <div className="title">{user.name}</div>
            <Link to="/auth/logout">Logout</Link>
        </div>
    </div>
};

const Side = props => {

    const auth = props.auth;
    const [userComponentVisible, setUserComponentVisible] = React.useState(false);

    return (
        <div css={sideCss}>
            <nav>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    {/* <li><Link to="/scan/delivery">Deliveries</Link></li> */}

                    <li onClick={() => {
                        setUserComponentVisible(!userComponentVisible)
                    }}>{userComponentVisible && <UserMenu user={auth.user} />}
                        <img className="trigger" src="/assets/img/svg/user.svg" alt="user" />
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Side;
import * as React from 'react';
import { Link } from 'react-router-dom';
/* @jsx jsx */ import { jsx, css } from '@emotion/core'; /* eslint-disable-line */

const breadcrumbsCss = css`
	background: #f5f5f5;
	padding: 20px 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	
	> div {		
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 25px;
		max-width: 1200px;
		margin: auto;
	}

	ul {
		display: flex;
		align-items: center;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			position: relative;
			line-height: 1;

			&:first-of-type a {
				margin: 0 10px 0 0;
			}

			a {
				font-size: 12px;
				margin: 0 12px;
				color: #3e3e3e;
				text-transform: uppercase;
				display: block;
				font-weight: 600;
				background-color: transparent;
			    text-decoration: none;
				transition: .4s;
				
				img {
					height: 14px;
				}
			}

			&+li::before {
				content: '';
				position: absolute;
				width: 7px;
				height: 7px;
				border-bottom: 2px solid #002bbb;
				border-right: 2px solid #002bbb;
				display: block;
				transform: translate(-50%,25%) rotate(-45deg);
			}
		}
	}
`;

const Breadcrumb = props => {

    return (
        <div css={breadcrumbsCss}>
            <div>
                <ul>
                    <li>
                        <Link to="/">
                            <img src="/assets/img/svg/home.svg" alt="" />
                        </Link>
                    </li>
                    <li><Link to="/">Scanning</Link></li>
                </ul>
            </div>
        </div>
    );
}

export default Breadcrumb;
export default function reducers(resourceName, args) {

    return {
        [resourceName + '_CREATE_REQUEST']: (state, payload) => {
            return Object.assign({}, state, {
                isRequestingCreate: true,
                hasCreated: false,
                errors: undefined
            });
        },

        [resourceName + '_CREATE_FAILURE']: (state, payload) => {
            return Object.assign({}, state, {
                isRequestingCreate: false,
                item: null,
                hasCreated: false,
                additional: payload.additional,
                errors: payload.errors
            });
        },

        [resourceName + '_CREATE_SUCCESS']: (state, payload) => {

            let d = {
                isRequestingCreate: false,
                hasCreated: true
            };

            if (!payload.ignoreCreateResponseData) {
                d.item = payload.data;    
            }

            return Object.assign({}, state, d);
        },

        [resourceName + '_CREATE_COMPLETE']: (state, payload) => {
            return Object.assign({}, state, {
                isRequestingCreate: false
            });
        }
    }
}
import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

const UserSection = Loadable({
    loader: () => import(/* webpackChunkName: "user-section" */ './users/Routes'),
    loading: () => null,
    modules: ['user-section'],
    webpack: () => [require.resolveWeak('./users/Routes')] // Prevents white flash
});

const OrdersSection = Loadable({
    loader: () => import(/* webpackChunkName: "order-section" */ './orders/Routes'),
    loading: () => null,
    modules: ['order-section'],
    webpack: () => [require.resolveWeak('./orders/Routes')] // Prevents white flash
});

const ProductsSection = Loadable({
    loader: () => import(/* webpackChunkName: "product-section" */ './products/Routes'),
    loading: () => null,
    modules: ['product-section'],
    webpack: () => [require.resolveWeak('./products/Routes')] // Prevents white flash
});

const CategoriesSection = Loadable({
    loader: () => import(/* webpackChunkName: "category-section" */ './categories/Routes'),
    loading: () => null,
    modules: ['category-section'],
    webpack: () => [require.resolveWeak('./categories/Routes')] // Prevents white flash
});

const VendorsSection = Loadable({
    loader: () => import(/* webpackChunkName: "vendor-section" */ './vendors/Routes'),
    loading: () => null,
    modules: ['vendor-section'],
    webpack: () => [require.resolveWeak('./vendors/Routes')] // Prevents white flash
});

const StoresSection = Loadable({
    loader: () => import(/* webpackChunkName: "store-section" */ './stores/Routes'),
    loading: () => null,
    modules: ['store-section'],
    webpack: () => [require.resolveWeak('./stores/Routes')] // Prevents white flash
});

export default ({ auth }) => {
    return <Switch>
        <Route path="/users" component={UserSection} />
        <Route path="/orders" component={OrdersSection} />
        <Route path="/products" component={ProductsSection} />
        <Route path="/categories" component={CategoriesSection} />
        <Route path="/v" component={VendorsSection} />
        <Route path="/s" component={StoresSection} />
        <Route key="logout" path={`/auth/logout`} component={require('app/modules/authentication/section/authentication/page/Logout').default} />
        {/* <Route key="unauthorized" path={`/auth/unauthorized`} component={require('app/modules/authentication/page/Unauthorized').default} /> */}
        <Route exact path="/">
            <Redirect to="/orders/dashboard" />
        </Route>
    </Switch>
}
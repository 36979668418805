export default function createActionCreators(resourceName, actionTypes, api, options) {
    let actionCreators = {

        create: function (data, auth, queryParams) {
            return function (dispatch) {
                dispatch(actionCreators.createRequest());

                return api.create(data, auth, queryParams)
                    .then(
                        response => dispatch(response.errors ? actionCreators.createFailure(response.errors, response.data) : actionCreators.createSuccess(response, options)),
                        exception => dispatch(actionCreators.createFailure(exception))
                    ).then(
                        response => dispatch(actionCreators.createComplete()),
                        exception => dispatch(actionCreators.createComplete())
                    );
            }
        },

        createRequest: function () {
            let name = resourceName + '_CREATE_REQUEST';

            return {
                type: actionTypes[name]
            }
        },

        createSuccess: function (data, options) {
            let name = resourceName + '_CREATE_SUCCESS';

            return {
                data: data,
                ignoreCreateResponseData: options.ignoreCreateResponseData,
                type: actionTypes[name]
            }
        },

        createComplete: function () {
            let name = resourceName + '_CREATE_COMPLETE';

            return {
                data: {},
                type: actionTypes[name]
            }
        },

        createFailure: function (errors, additional) {
            let name = resourceName + '_CREATE_FAILURE';

            return {
                errors: errors,
                additional: additional,
                type: actionTypes[name]
            }
        }
    };

    return actionCreators;
}
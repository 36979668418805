import * as React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, info: null };
    }

    componentDidCatch(error, info) {
        console.log("CDC", error);
        this.setState({ hasError: true, error: error, info: info });
    }

    render() {
        if (this.state.hasError) {
            console.log("EB", this.state.error);
            return <div>
                <h1>Something went wrong.</h1>
                <a href="/">Home</a>
                <pre>{this.state.error.stack}</pre>
            </div>;
        }
        return this.props.children;
    }
}
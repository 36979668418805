import * as React from 'react';
import { Link } from 'react-router-dom';
import { matchPath } from 'react-router';
/* @jsx jsx */ import { css, jsx } from '@emotion/core'; /* eslint-disable-line */

import Breadcrumb from './Breadcrumb';
import Side from './Side';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

const headerCss = css`
	padding: 15px 40px 15px 20px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(178deg,#00adee 0,#002bbb 100%);
    box-shadow: 0 3px 7px 0 rgba(0,0,0,.35);
	
	> div {		
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: auto;
	}

	.logo {
		height: 30px;
	}
`;

const Header = props => {

    const pathname = props.location.pathname;
    const authedUsername = props.auth.user && props.auth.user.name;

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    return (
        <header>
            <div css={headerCss}>
                <div>
                    <Link to="/">
                        <img className="logo" src="/assets/logo-hds-grey.png" alt="" />
                    </Link>
                </div>
            </div>
            {/* <Breadcrumb /> */}
        </header>
    );
}

export default Header;
// The basics
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/* @jsx jsx */ import { css, jsx } from '@emotion/core'; /* eslint-disable-line */
import Routes from './modules/Routes';
import { withRouter } from 'react-router';
import SiteNavigation from './components/SiteNavigation';
import SiteHeader from './components/siteHeader/Header';

import * as verifyTokenAC from './modules/authentication/action/VerifyToken';
import * as searchParamsAC from './modules/authentication/action/SearchParams';
import AuthCheck from './modules/authentication/component/AuthCheck';
import Loadable from 'react-loadable';

import ErrorBoundary from 'app/components/ErrorBoundary';
import Cookies from 'js-cookie';
import { Switch, Route, Redirect } from 'react-router-dom';

import config from 'app/config';

const Authentication = Loadable({
    loader: () => import(/* webpackChunkName: "authentication" */ './modules/authentication/Routes'),
    loading: () => null,
    modules: ['authentication'],
    webpack: () => [require.resolveWeak('./modules/authentication/Routes')] // Prevents white flash
});


const scCss = css`
    display: flex;
    height: calc(100vh - 64px);
    overflow: hidden;
`;

const pageMainCss = css`
    flex: 1 1 auto;
    padding: 20px 40px 0 30px;
    background: #f0f0f0;
    overflow-y: auto;
`;

const publicCss = css`
    width: 100%;
    height: 100%;
`

class App extends React.Component {

    componentDidMount() {
        this.props.vtActions.verifyToken(Cookies.get(config.cookieName));
        this.props.spActions.decodeSearchParams(this.props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.props.spActions.decodeSearchParams(this.props);
        }

        if (this.props.auth.isAuthenticated && this.props.searchParams.hasDecoded && this.props.location.pathname === '/') {
            this.props.history.push({ pathname: '/orders/dashboard' });
        }

        if (this.props.auth.isAuthenticated && this.props.searchParams.hasDecoded && this.props.searchParams.nextPath) {
            this.props.history.push({ pathname: this.props.searchParams.nextPath });
        }
    }

    render() {

        const props = this.props;

        const renderSiteHeader = props.location.pathname.startsWith('/special') ? false : true;
        const readerSiteNav = props.location.pathname.startsWith('/special') ? false : true;

        if (!props.auth.isAuthenticated && !props.auth.didAttemptTokenVerification) {
            return <AuthCheck />
        }

        if (!props.auth.isAuthenticated) {
            return <div css={publicCss}>
                <Switch>
                    <Route path="/auth" component={Authentication} />
                    <Redirect to={`/auth/login?nextPath=${props.location.pathname}`} />
                </Switch>
            </div>
        }

        return (
            <div id="app">
                <ErrorBoundary>
                    {renderSiteHeader && <SiteHeader auth={this.props.auth} location={this.props.location} />}
                    <div id="site-container" css={readerSiteNav ? scCss : null}>
                        {readerSiteNav && <SiteNavigation auth={this.props.auth} location={this.props.location} />}
                        <main id="page-main" css={readerSiteNav ? pageMainCss : publicCss}>
                            <Routes auth={this.props.auth} />
                        </main>
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    searchParams: state.searchParams
});

const mapDispatchToProps = (dispatch) => ({
    vtActions: bindActionCreators(verifyTokenAC, dispatch),
    spActions: bindActionCreators(searchParamsAC, dispatch)
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);

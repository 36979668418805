import {
    VERIFY_TOKEN_REQUEST, 
    VERIFY_TOKEN_SUCCESS, 
    VERIFY_TOKEN_FAILURE
} from '../constants/Authentication';

import currentUserApi from '../resources/currentUser';

export function verifyToken(token, type) {
    return function(dispatch) {

        verifyTokenRequest();

        return currentUserApi.get(token)
            .then(response => {

                // if (response.type !== 'ADMIN') {
                //     dispatch(verifyTokenFailure(token, {}));
                //     return;
                // }

                dispatch(verifyTokenSuccess(token, response));
            }).catch(error => {
                console.log("ERROR", error);
                dispatch(verifyTokenFailure(token, error));
            });
    }
}

export function verifyTokenRequest() {
    return {
        type: VERIFY_TOKEN_REQUEST
    }
}

export function verifyTokenSuccess(token, user) {
    return {
        type: VERIFY_TOKEN_SUCCESS,
        payload: {
            token: token,
            user: user
        }
    }
}

export function verifyTokenFailure(token, data) {

    let errorData = {};
    if (data.error !== undefined && data.error.status !== undefined) {
        errorData.status = data.error.status;
    }

    if (data.tokenDeveloperMessage !== undefined && data.error.tokenDeveloperMessage !== undefined) {
        errorData.tokenDeveloperMessage = data.error.tokenDeveloperMessage;
    }

    return {
        type: VERIFY_TOKEN_FAILURE,
        payload: {
            token: token,
            status: errorData.status,
            tokenDeveloperMessage: errorData.tokenDeveloperMessage
        }
    }
}
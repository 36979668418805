let authenticationURI = 'http://localhost:5800/api',
    baseURI = 'http://localhost:5800/api';

if (process.env.REACT_APP_AUTH_URI) {
    authenticationURI = process.env.REACT_APP_AUTH_URI;
}
if (process.env.REACT_APP_BASE_URI) {
    baseURI = process.env.REACT_APP_BASE_URI;
}

console.log(baseURI);
export default {
    cookieName: 'bp-predictive-ordering',
    secureCookies: baseURI.startsWith('http://localhost') ? false : true,
    authenticationURI: authenticationURI,
    baseURI: baseURI
}

console.log('auth url', authenticationURI);